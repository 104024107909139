import React from 'react';

import usePricesProjections from '@hooks/use-prices-projection';

import configuration from '@config';
import { useAuth } from '@src/auth-wrapper';

import { PriceProjectionsHistoryTable } from '@components/organisms/price-projections-history/table';
import { PriceProjectionsHistoryNoDataMessage } from '@components/atoms/price-projections-history/messages';

export function PriceProjectionsHistoryCore() {
  const { pricesProjections, partnerId, setPartnerId } = usePricesProjections();
  const { user } = useAuth();

  React.useEffect(() => {
    setPartnerId(configuration.MAIN_PARTNER_ID);
  }, []);

  if (partnerId) {
    if (pricesProjections && pricesProjections?.total > 0) {
      return <PriceProjectionsHistoryTable />;
    } else return <PriceProjectionsHistoryNoDataMessage isAdmin={user?.isAdmin} />;
  } else return <></>;
}
